<template>
  <div>
    <action-header
      :is-loading="!notificationTask"
      :actions-model="headerModel"
      :page="page"
    />

    <div class="q-pa-md">
      <form-builder :schema="schema" />

      <div class="row items-center">
        <div class="col">
          <q-input
            standout="bg-teal text-white"
            type="text"
            :label="$t('Template')"
            :model-value="(notificationTask || {}).template"
            required
            @update:model-value="handleTemplateUpdate"
          />
        </div>

        <q-btn-dropdown
          color="primary"
          :label="$t('Bind')"
          no-caps
          class="q-ml-sm"
          style="min-width: 100px;"
        >
          <q-list style="min-width: 150px;">
            <q-item
              v-for="field in popoverFields"
              :key="field"
              v-close-popup
              clickable
              @click="addField(field)"
            >
              <q-item-section>
                <q-item-label>
                  {{ field }}
                </q-item-label>
              </q-item-section>
            </q-item>
          </q-list>
        </q-btn-dropdown>
      </div>

      <q-table
        v-if="notificationTask"
        row-key="extId"
        class="full-width q-ma-sm"
        :rows-per-page-label="$t('Rows per page')"
        :rows="notificationTask.conditions || []"
        :columns="columns"
        :pagination="{ page: 1, rowsPerPage: 250, totalRowsNumber: (notificationTask.conditions || []).length }"
        virtual-scroll
      >
        <template v-slot:body="props">
          <q-tr :props="props">
            <q-td
              key="field"
              :props="props"
            >
              <q-select
                standout="bg-teal text-white"
                :model-value="props.row.field"
                :options="fields"
                :label="$t('Field')"
              >
                <template
                  v-slot:option="{ opt }"
                >
                  <div
                    class="q-pa-sm card--clickable card--clickable-no-scale"
                    @click="updateRow(props.rowIndex, 'field', opt)"
                  >
                    {{ opt }}
                  </div>
                </template>
              </q-select>
            </q-td>

            <q-td
              key="type"
              :props="props"
            >
              <q-select
                standout="bg-teal text-white"
                :model-value="props.row.type"
                :options="operators"
                :label="$t('Type')"
              >
                <template
                  v-slot:option="{ opt }"
                >
                  <div
                    class="q-pa-sm card--clickable card--clickable-no-scale"
                    @click="updateRow(props.rowIndex, 'type', opt)"
                  >
                    {{ opt }}
                  </div>
                </template>
              </q-select>
            </q-td>

            <q-td
              key="value"
              :props="props"
            >
              <q-input
                standout="bg-teal text-white"
                type="text"
                :label="$t('Value')"
                :model-value="props.row.value"
                required
                @update:model-value="updateRow(props.rowIndex, 'value', $event)"
              />
            </q-td>

            <q-td
              key="actions"
              :props="props"
            >
              <q-btn
                color="negative"
                text-color="white"
                icon="delete"
                no-caps
                unelevated
                @click="removeRow(props.rowIndex)"
              />
            </q-td>
          </q-tr>
        </template>
      </q-table>

      <div class="q-py-sm">
        <q-btn
          color="light-blue-9"
          text-color="white"
          :label="$t('Add row')"
          class="full-width"
          no-caps
          unelevated
          @click="addRow"
        />
      </div>
    </div>

    <sticky-bottom-header
      :is-loading="isSaveLoading"
      :is-active="hasChange"
      @back="handleDiscard"
      @save="save"
    />
  </div>
</template>

<script>
// Vuex
import { mapActions, mapGetters, mapMutations } from 'vuex'

// Components
import ActionHeader from './../../components/action-header/ActionHeader'

export default {
  name: 'Task',
  components: {
    ActionHeader
  },
  data () {
    return {
      isSaveLoading: false,
      hasChange: false,
      popoverFields: [
        'id',
        'trackingNumber',
        'deliveryService.name',
        'extId',
        'payment'
      ],
      events: [
        'delivery.services.request.saved:deliveryRequest'
      ],
      fields: [
        'deliveryService',
        'state',
        'trackingNumber',
        'sender',
        'shop'
      ],
      operators: [
        'eq',
        'neq',
        'in',
        'isnotnull',
        'isnull'
      ],
      columns: [
        {
          label: this.$t('Field'),
          name: 'field',
          align: 'center'
        },
        {
          label: this.$t('Type'),
          name: 'type',
          align: 'center'
        },
        {
          label: this.$t('Value'),
          name: 'value',
          align: 'center'
        },
        {
          label: this.$t('Actions'),
          name: 'actions',
          align: 'right'
        }
      ],
      states: [
        { id: 'active', name: this.$t('Active') },
        { id: 'inactive', name: this.$t('Inactive') },
        { id: 'deleted', name: this.$t('Deleted') }
      ]
    }
  },
  computed: {
    ...mapGetters([
      'notificationTask'
    ]),
    schema () {
      return {
        isLoading: !this.notificationTask,
        groups: [
          {
            styleClasses: 'row',
            fields: [
              {
                type: 'input',
                inputType: 'text',
                label: this.$t('Name'),
                field: 'name',
                value: this.notificationTask && this.notificationTask.name,
                required: true,
                wrapperStyleClasses: 'col-12 col-md-6 q-pa-xs',
                onChange: name => {
                  this.updateNotificationTask({ name })
                  this.hasChange = true
                }
              },
              {
                type: 'select',
                label: this.$t('Event'),
                field: 'event',
                value: this.notificationTask && this.notificationTask.event,
                wrapperStyleClasses: 'col-12 col-md-6 q-pa-xs',
                options: this.events,
                required: true,
                onChange: (event) => {
                  this.updateNotificationTask({ event })
                  this.hasChange = true
                }
              },
              {
                type: 'input',
                inputType: 'text',
                label: this.$t('Title'),
                field: 'title',
                value: this.notificationTask && this.notificationTask.title,
                required: true,
                wrapperStyleClasses: 'col-12 col-md-6 q-pa-xs',
                onChange: title => {
                  this.updateNotificationTask({ title })
                  this.hasChange = true
                }
              },
              {
                type: 'input',
                inputType: 'text',
                label: this.$t('Delay'),
                field: 'delay',
                value: this.notificationTask && this.notificationTask.delay,
                required: true,
                wrapperStyleClasses: 'col-12 col-md-6 q-pa-xs',
                onChange: delay => {
                  this.updateNotificationTask({ delay })
                  this.hasChange = true
                }
              },
              {
                type: 'select',
                label: this.$t('Status'),
                field: 'state',
                value: this.notificationTask && this.notificationTask.state,
                wrapperStyleClasses: 'col-12 col-md-6 q-pa-xs',
                options: this.states,
                customLabel (row) {
                  return row && typeof row === 'object'
                    ? row.name
                    : row
                },
                onChange: (state) => {
                  this.updateNotificationTask({ state: state.id })
                  this.hasChange = true
                }
              },
              {
                type: 'multiselect',
                label: this.$t('Channel'),
                wrapperStyleClasses: 'col-12 col-md-6 q-pa-xs',
                field: 'channel',
                value: this.notificationTask && this.notificationTask._embedded.channel,
                customLabel (row) {
                  if (row && typeof row === 'object') {
                    return `${row.name} (${row.id})`
                  }

                  return row
                },
                onScroll: (search, page) => {
                  const query = {
                    per_page: 25,
                    page,
                    search
                  }

                  return this.$service.notificationChannel.getAll(query)
                },
                onChange: channel => {
                  this.updateNotificationTaskEmbedded({ channel })
                  this.hasChange = true
                }
              }
            ]
          }
        ]
      }
    },
    page () {
      return {
        id: this.notificationTask && this.notificationTask.id,
        name: this.notificationTask && this.notificationTask.id
          ? this.$t('Task')
          : this.$t('New task')
      }
    },
    headerModel () {
      if (!this.notificationTask) {
        return []
      }

      return [
        {
          section: 'BackAction',
          className: 'q-pr-sm hide-on-mobile',
          options: [
            {
              id: 'back',
              type: 'button',
              icon: 'arrow_back',
              variant: 'light',
              style: 'white-space: nowrap;',
              label: this.$t('Back'),
              onClick: this.handleBack
            }
          ]
        },
        {
          section: 'Title',
          className: 'col q-px-xs text-white mobile-title',
          options: [
            {
              id: 'title',
              type: 'text',
              value: this.notificationTask && this.notificationTask.id
                ? this.$t('Task ID: ') + this.notificationTask.id
                : this.$t('New Task')
            }
          ]
        }
      ]
    }
  },
  mounted () {
    if (this.$route.params.id && !this.notificationTask) {
      this.loadNotificationTask(this.$route.params.id)
    }

    if (!this.$route.params.id) {
      this.setNewNotificationTask()

      const owner = JSON.parse(localStorage.getItem('userData'))
      this.updateNotificationTask({
        _embedded: {
          ...this.notificationTask._embedded,
          owner
        }
      })
    }
  },
  unmounted () {
    this.setNotificationTask(null)
  },
  methods: {
    ...mapActions([
      'loadNotificationTask',
      'saveNotificationTask'
    ]),
    ...mapMutations([
      'setNewNotificationTask',
      'setNotificationTask',
      'updateNotificationTask',
      'updateNotificationTaskEmbedded'
    ]),
    handleTemplateUpdate (template) {
      this.updateNotificationTask({ template })
      this.hasChange = true
    },
    addField (field) {
      this.hasChange = true
      this.updateNotificationTask({
        template: `${this.notificationTask.template}{{${field}}}`
      })
    },
    updateRow (index, key, value) {
      this.hasChange = true
      this.updateNotificationTask({
        conditions: this.notificationTask.conditions.map((field, i) => {
          if (i === index) {
            const newField = {...field}
            newField[key] = value
            return newField
          }

          return field
        })
      })
    },
    removeRow (index) {
      this.hasChange = true
      this.updateNotificationTask({
        conditions: [
          ...this.notificationTask.conditions.slice(0, index),
          ...this.notificationTask.conditions.slice(index + 1)
        ]
      })
    },
    addRow () {
      this.hasChange = true
      this.updateNotificationTask({
        conditions: [
          ...this.notificationTask.conditions,
          { field: '', type: '', value: '' }
        ]
      })
    },
    refresh () {
      this.loadTask(this.$route.params.id)
    },
    save () {
      this.isSaveLoading = true
      this.saveNotificationTask()
        .then(() => {
          this.hasChange = false
          if (!this.notificationTask.id) {
            this.handleBack()
          }
        })
        .finally(() => {
          this.isSaveLoading = false
        })
    },
    handleDiscard () {
      this.$router.go()
    },
    handleBack () {
      this.$router.back()
    }
  }
}
</script>
